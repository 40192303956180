/*
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2024-02-26 17:48:05
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-02-26 19:43:03
 */
export default {
  data () {
    return {
    }
  },
  methods: {
    // 单元格的 style 的回调方法--首列表头样式
    cellHeaderStyle ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return 'padding-left:32px;'
      } else {
        return ''
      }
    },
    // 单元格的 style 的回调方法--首列表格内容样式
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return 'padding-left:32px;'
      } else {
        return ''
      }
    },
    // 第二列
    // 单元格的 style 的回调方法--首列表头样式
    cellHeaderStyleTwo ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 1) {
        return 'padding-left:0px;'
      } else {
        return ''
      }
    },
    // 单元格的 style 的回调方法--首列表格内容样式
    cellStyleTwo ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 1) {
        return 'padding-left:0px;'
      } else {
        return ''
      }
    }
  }
}
